import React from 'react';
import { useContext } from 'react';
import Context from '../context/Context';
import { Link, Outlet } from 'react-router-dom';
import SkipToContent from '../components/SkipToContent';
import Loader from '../components/Loader';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Layout = () => {
    const {isLoading, isError, errorMessage} = useContext(Context);
    const mainHeight = `calc(100vh - ${document.querySelector('.app-header')?.offsetHeight}px)`;

    return (
        <div className="App">
            <SkipToContent />

            <Header />
        
            <main id="main" className={`wrapper relative py-8 md:py-10 flow-y`} role="main" style={{minHeight: mainHeight}} tabIndex={-1}>
                {isError ? (
                    <>
                        <h2>Error...</h2>
                        <p>{errorMessage} <Link to="/">Return to home</Link></p>
                    </>
                ) : (
                    <>
                        {isLoading && <Loader />}
                        <Outlet />
                    </>
                )}
            </main>

            <Footer />
        </div>
    )
}

export default Layout;
