import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Loader from '../components/Loader';

const IFrame = (props) => {
    const {currentDoc} = props;
    const [iframeLoading, setIframeLoading] = useState(false);
    const iframe = useRef(null);

    useEffect(() => {
        setIframeLoading(true);
    }, []);

    return (
        <>
            {iframeLoading && <Loader />}
            
            <iframe className="border border-gray-300 h-[460px] md:h-[800px]" width="100%" height="100%" ref={iframe} title={currentDoc?.title} aria-label={currentDoc?.title} src={currentDoc?.archive_url} onLoad={() => setIframeLoading(false)} />
            
        </>
    )
}

export default IFrame;
