import React from 'react';

const Anchor = (props) => {
    const {classNames, attributes, onClick, children} = props;

    return (
        <a className={`${classNames ? classNames : ''}`} {...attributes} onClick={onClick}>
            {children}
        </a>
    )
}

export default Anchor;
