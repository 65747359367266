import React from 'react';
import { useContext } from 'react';
import Context from '../context/Context';
import Search from '../components/Search';

const Home = () => {
    const { appName, screenWidth } = useContext(Context);

    document.title = `Home | ${appName}`;

    return (
        <>
            {screenWidth < 768 ? (
                <section className="flow-y">
                    <Search />

                    <img src="./lab.jpg" alt="Woman in a labratory running tests while sitting next to a couple of vials and a microscope" />
                    <h2>CDC Archive Home</h2>
                    <p>Archived web material for CDC.gov is preserved on the CDC Archive Site. Note that the content on this site is for historical purposes only and is no longer being updated. The information here may be outdated and links may no longer function.</p>
                </section>
            ) : (
                <section className="grid md:grid-cols-2 gap-y-8 md:gap-x-8">
                    <div className="flow-y">
                        <h2>CDC Archive Home</h2>
                        <p>Archived web material for CDC.gov is preserved on the CDC Archive Site. Note that the content on this site is for historical purposes only and is no longer being updated. The information here may be outdated and links may no longer function.</p>

                        <Search />
                    </div>
                    
                    <div>
                        <img className="w-100 print:w-1/2" src="./lab.jpg" alt="Woman in a labratory running tests while sitting next to a couple of vials and a microscope" />
                    </div>
                </section>
            )}
        </>
    )
}

export default Home;
