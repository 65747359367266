import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Context from '../context/Context';
import Button from '../objects/Button';
import { handleInteractionBeacon } from '../helpers/handleInteractionBeacon';

const Search = (props) => {
    const { label } = props;
    const { appSites, criteria, setCriteria, currentCriteria, setCurrentCriteria, screenWidth, handleCriteriaReset } = useContext(Context);

    const [ warning, setWarning ] = useState(false);
    const [ advancedSearchActive, setAdvancedSearchActive ] = useState(false);
    const [ height, setHeight ] = useState(0);

    const searchForm = useRef(null);
    const advancedSearch = useRef(null);
    const advancedSearchInnerBody = useRef(null);
    const searchTextField = useRef(null);
    const searchDomainField = useRef(null);
    const searchSitesField = useRef(null);
    const searchFromDateField = useRef(null);
    const searchToDateField = useRef(null);

    const location = useLocation();

    useEffect(() => {
        if (advancedSearchInnerBody) {
            setHeight(advancedSearchInnerBody.current.offsetHeight);
        }
    }, [advancedSearchInnerBody]);

    const dynamicStyles = {
        maxHeight: advancedSearchActive ? `${height}px` : '0px'
    }

    let todaysDate = (new Date()).toLocaleString("en",{"year": "numeric", "month": "numeric", "day": "numeric"});
    todaysDate = todaysDate.split('/');
    todaysDate = `${todaysDate[2]}-${todaysDate[0]}-${('0' + todaysDate[1]).slice(-2)}`;

    const formSubmitHandler = (event) => {
        event.preventDefault();

        if (
            searchTextField.current.value && searchTextField.current.value.replace(/\s/g, '').length > 0
        ) {
            handleInteractionBeacon( 'cdcarchive-search-form', 'o', event.type );

            setCriteria(currentCriteria);
        }
        else {
            setWarning(true);
        }
    }

    const formChangeHandler = (event) => {
        if (event.target === searchDomainField.current) {
            setCurrentCriteria(prevState => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    domain: event.target.value
                }
            }));
        }
        else if (event.target === searchSitesField.current) {
            setCurrentCriteria(prevState => ({
                ...prevState,
                filters: {
                    ...prevState.filters,
                    sites: event.target.value
                }
            }));
        }
        else if (event.target === searchFromDateField.current || event.target === searchToDateField.current) {
            if (event.target === searchFromDateField.current) {
                setCurrentCriteria(prevState => ({
                    ...prevState,
                    filters: {
                        ...prevState.filters,
                        dates: {
                            ...prevState.filters.dates,
                            from: searchFromDateField.current.value
                        }
                    }
                }));
            }
            else if (event.target === searchToDateField.current) {
                setCurrentCriteria(prevState => ({
                    ...prevState,
                    filters: {
                        ...prevState.filters,
                        dates: {
                            ...prevState.filters.dates,
                            to: searchToDateField.current.value
                        }
                    }
                }));
            }
        }
        else {
            setCurrentCriteria(prevState => ({
                ...prevState,
                q: event.target.value
            }));
        }
    };

    return (
        <>
            {/* Only show on home page and on larger screens */}
            {location.pathname === '/' && screenWidth > 768 && (
                <p className="h3">Search the Archives</p>
            )}
        
            <form className={`rounded ${location.pathname === '/' && screenWidth > 768 ? 'md:bg-gray-200 md:p-4' : ''}`} ref={searchForm} onSubmit={(event) => {
                formSubmitHandler(event);
            }}>
                <div>
                    <label className={`inline-block mb-2`} htmlFor="search">{label ? label : 'Search Text'}</label>
                    <div className="sm:flex items-center">
                        <div className="grow relative">
                            <input
                                id="search"
                                className={`form-control w-full ${warning ? 'bg-amber-quaternary' : ''}`}
                                name="search"
                                type="text"
                                defaultValue={criteria.q.trim().length > 0 ? criteria.q : ''}
                                placeholder="Search"
                                ref={searchTextField}
                                // Only run if we have a value
                                onChange={(event) => {
                                    formChangeHandler(event);

                                    // Resets warning if users start typing
                                    if (warning && (event.target.value !== '' || event.target.value.length > 0)) setWarning(false);
                                }}
                            />
                            
                            {/* Search icons (magnifying class, close) */}
                            <div className="flex items-center absolute top-[50%] translate-y-[-50%] right-2">
                                {/* Show magnifying glass icon on mobile and on /results page */}
                                <button
                                    className={`w-[20px] h-[20px] ${location.pathname === '/' && screenWidth > 768 ? '' : ''}`}
                                    tabIndex={location.pathname === '/' && screenWidth > 768 ? '-1' : '0'}
                                    onClick={(event) => {
                                        handleInteractionBeacon( 'cdcarchive-search-link', 'o', event.type );
                                    }}
                                >
                                    <span className="sr-only">Select to search</span>
                                    <svg className={`fill-gray-500 hover:fill-blue`} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M14.146 14.854l.708-.708-4.272-4.272C11.466 8.83 12 7.477 12 6c0-3.314-2.686-6-6-6-3.314 0-6 2.686-6 6 0 3.314 2.686 6 6 6 1.477 0 2.83-.534 3.874-1.418l4.272 4.272zM11 6c0 2.761-2.239 5-5 5-2.761 0-5-2.239-5-5 0-2.761 2.239-5 5-5 2.761 0 5 2.239 5 5z" /></svg>
                                </button>
                                
                                {/* <button
                                    className="flex items-center w-[20px] h-[20px]"
                                >
                                    <span className="sr-only">Select to clear search</span>
                                    <svg className="fill-gray-500 hover:fill-blue" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"/></svg>
                                </button> */}
                            </div>
                        </div>
                        <button
                            id="advanced-search"
                            className="relative flex items-center text-blue-link sm:ml-4"
                            aria-expanded={advancedSearchActive ? true : false}
                            onClick={(event) => {
                                event.preventDefault();
                                setAdvancedSearchActive(!advancedSearchActive);
                                handleInteractionBeacon( 'cdcarchive-advancedsearchopen-link', 'o', event.type );
                            }}
                        >
                            Advanced Search <svg className={`w-[1.5rem] fill-gray-700 transition-transform ${advancedSearchActive ? 'scale-y-[-1]' : 'scale-y-[1]'}`} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 96 960 960"><path d="M480 711 240 471l47.333-47.333L480 617.001l192.667-192.667L720 471.667 480 711Z"/></svg>
                        </button>
                    </div>

                    {warning && (
                        <p className="text-pink text-sm mt-1">Search input cannot be empty.</p>
                    )}
                </div>

                {/* Advanced Search */}
                <div
                    className={`sm:mt-2 mb-2 box-content overflow-hidden transition-[max-height] duration-[400ms] ease-[cubic-bezier(1, 0.02, 0, 0.99)] ${location.pathname === '/results' ? '' : ''} ${advancedSearchActive && location.pathname === '/results' ? '' : ''} ${advancedSearchActive ? '' : ''}`}
                    aria-labelledby="advanced-search"
                    aria-expanded={advancedSearchActive ? true : false}
                    aria-hidden={advancedSearchActive ? false : true}
                    ref={advancedSearch}
                    style={dynamicStyles}
                >
                    <div className={`rounded bg-gray-200 ${location.pathname === '/results' ? 'p-3 md:p-4' : ''} flow-y`} ref={advancedSearchInnerBody}>
                        <div className="flex items-center">
                            <label htmlFor="domain" className="font-bold w-1/4 lg:w-1/5">Domain:</label>
                            <div className="w-3/4 lg:w-4/5">
                                <select
                                    id="domain"
                                    className={`form-control w-full`}
                                    ref={searchDomainField}
                                    tabIndex={advancedSearchActive ? '0' : '-1'}
                                    data-filter="original_url"
                                    key={criteria.filters.domain.trim().length > 0 ? criteria.filters.domain : ''}
                                    defaultValue={criteria.filters.domain.trim().length > 0 ? criteria.filters.domain : ''}
                                    onChange={(event) => {
                                        formChangeHandler(event);
                                    }}
                                >
                                    <option value="">All Domains</option>
                                    <option value="https://www.cdc.gov/">www.cdc.gov</option>
                                    <option value="https://www.atsdr.cdc.gov/">www.atsdr.cdc.gov</option>
                                    <option value="https://emergency.cdc.gov/">emergency.cdc.gov</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <label htmlFor="sites" className="font-bold w-1/4 lg:w-1/5">Site:</label>
                            <div className="w-3/4 lg:w-4/5">
                                <select
                                    id="sites"
                                    className={`form-control w-full`}
                                    ref={searchSitesField}
                                    tabIndex={advancedSearchActive ? '0' : '-1'}
                                    data-filter="original_url"
                                    key={criteria.filters.sites.trim().length > 0 ? criteria.filters.sites : ''}
                                    defaultValue={criteria.filters.sites.trim().length > 0 ? criteria.filters.sites : ''}
                                    onChange={(event) => {
                                        formChangeHandler(event);
                                    }}
                                >
                                    <option value="">All Sites</option>
                                    {appSites && Object.keys(appSites).length && (
                                        Object.keys(appSites).map((name, index) => {
                                            return <option key={index} value={name}>{name}</option>
                                        })
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="flex items-end">
                            <p className="font-bold py-[10px] w-1/4 lg:w-1/5">Date last modified:</p>
                            <div className="w-3/4 lg:w-4/5">
                                <div className="flex items-center">
                                    <div className="w-1/2 pr-1">
                                        <label htmlFor="from" className="font-bold">From:</label>
                                        <input
                                            id="from"
                                            className={`form-control w-full`}
                                            type="date"
                                            ref={searchFromDateField}
                                            tabIndex={advancedSearchActive ? '0' : '-1'}
                                            data-filter="date_last_modified"
                                            key={criteria.filters.dates.from.trim().length > 0 ? criteria.filters.dates.from : ''}
                                            defaultValue={criteria.filters.dates.from.trim().length > 0 ? criteria.filters.dates.from : ''}
                                            onChange={(event) => {
                                                formChangeHandler(event);
                                            }}
                                        />
                                    </div>
                                    <div className="w-1/2 pl-1">
                                        <label htmlFor="to" className="font-bold">To:</label>
                                        <input
                                            id="to"
                                            max={todaysDate}
                                            className={`form-control w-full`}
                                            type="date"
                                            ref={searchToDateField}
                                            tabIndex={advancedSearchActive ? '0' : '-1'}
                                            data-filter="date_last_modified"
                                            key={criteria.filters.dates.to.trim().length > 0 ? criteria.filters.dates.to : ''}
                                            defaultValue={criteria.filters.dates.to.trim().length > 0 ? criteria.filters.dates.to : ''}
                                            onChange={(event) => {
                                                formChangeHandler(event);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {location.pathname === '/results' && (
                            <div className="flow-x-sm flex justify-end">
                                <Button
                                    classNames="btn bg-blue hover:bg-blue-600 transition ease-[cubic-bezier(1, 0.5, 0, 0.74)] text-white"
                                    attributes={{
                                        tabIndex: advancedSearchActive ? '0' : '-1'
                                    }}
                                    onClick={(event) => {
                                        handleInteractionBeacon( 'cdcarchive-search-link', 'o', event.type );
                                    }}
                                >
                                    <span className="sr-only">Select to </span>Search
                                </Button>
                                <Button
                                    classNames="btn bg-gray-500 hover:bg-gray-600 transition ease--cubic-bezier(1, 0.5, 0, 0.74) text-white"
                                    attributes={{
                                        tabIndex: advancedSearchActive ? '0' : '-1'
                                    }}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        
                                        searchTextField.current.value = '';
                                        searchDomainField.current.value = '';
                                        searchSitesField.current.value = '';
                                        searchFromDateField.current.value = '';
                                        searchToDateField.current.value = '';

                                        setCurrentCriteria(prevState => ({
                                            ...prevState,
                                            q: '',
                                            filters: {
                                                ...prevState.filters,
                                                domain: '',
                                                sites: '',
                                                dates: {
                                                    ...prevState.filters.dates,
                                                    from: '',
                                                    to: ''
                                                }
                                            }
                                        }));

                                        setWarning(false);
                                        handleInteractionBeacon( 'cdcarchive-clear-link', 'o', event.type );
                                    }
                                }>
                                    <span className="sr-only">Select to </span>Clear
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

                {/* Only show on home page and on larger screens */}
                {location.pathname === '/' && screenWidth > 768 && (
                    <div className={`flex items-center flow-x-sm ${advancedSearchActive ? '' : 'no-flow'}`}>
                        <Button
                            classNames="btn bg-blue hover:bg-blue-600 transition ease-[cubic-bezier(1, 0.5, 0, 0.74)] text-white"
                            onClick={(event) => {
                                handleInteractionBeacon( 'cdcarchive-search-link', 'o', event.type );
                            }}
                        >
                            <span className="sr-only">Select to </span>Search
                        </Button>
                        <Button
                            classNames="btn disabled:bg-gray-400 bg-gray-500 hover:bg-gray-600 transition ease--cubic-bezier(1, 0.5, 0, 0.74) text-white"
                            onClick={(event) => {
                                event.preventDefault();
                                        
                                searchTextField.current.value = '';
                                searchDomainField.current.value = '';
                                searchSitesField.current.value = '';
                                searchFromDateField.current.value = '';
                                searchToDateField.current.value = '';
                                
                                setWarning(false);
                                handleInteractionBeacon( 'cdcarchive-clear-link', 'o', event.type );
                            }
                        }>
                            <span className="sr-only">Select to </span>Clear
                        </Button>
                    </div>
                )}
            </form>
        </>
    )
}

export default Search;
