export const handleInteractionBeacon = (eventLabel = '', eventType, eventValue) => {
    if ( window.hasOwnProperty( '_satellite' ) ) {
        const dataObject = {};
        const _satellite = window._satellite;

        eventType = eventType || 'o';
        window.s.pageName = document.title;
        dataObject.pageName = document.title;
        dataObject.label = eventLabel;
        dataObject.interactionType = eventType;
        dataObject.interactionValue = 'ci-' + eventLabel + ': ' + eventValue;
        dataObject.prop46 = window.location.href;
 
        _satellite.track( 'interaction', dataObject );
    }
};
