import React from 'react';
import { useState, useContext } from 'react';
import Context from '../context/Context';
import Search from '../components/Search';

const FourZeroFour = () => {
    const {appName} = useContext(Context);
    const [alphabet] = useState([...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']);

    document.title = `404 | ${appName}`;

    return (
        <div className="">
            <div className="no-flow mb-10">
                <h2>Oops!</h2>
                <p>We can’t seem to find the page you were looking for. Please try searching again or searching CDC.gov A-Z indexes.</p>
            </div>

            <div className="no-flow mb-10">
                <Search label={`Search CDC Archives`} />
            </div>

            <div className="no-flow mb-10">
                <h3 className="h4">Browse Health Topics by letter on CDC.gov</h3>
                <hr className="border-t-2 my-6" />
                <ul className="flex flex-wrap -mx-4">
                    {alphabet.map((letter, index) => {
                        return (
                            <li className="mx-4" key={index}>
                                <a className="text-3xl font-semibold text-gray-500 hover:text-blue hover:underline" href={`https://www.cdc.gov/health-topics.html#${letter.toLowerCase()}`} target="_blank" title={`Search CDC.gov "${letter}" health topics`}>{letter}</a>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default FourZeroFour;
