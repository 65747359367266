import { useState, useEffect } from 'react';

const useScreen = () => {
    const [screenWidth, setScreenWidth] = useState(0);
    const [screenType, setScreenType] = useState('xs');

    useEffect(() => {
        let timeout = false;
        let delay = 250;
        const screens = {
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1400
        }

		const handleSetScreenSize = () => {
            let width = window.innerWidth;

            setScreenWidth(width);

            if (width < screens.sm) {
                setScreenType('xs');
            }
			else if (width >= screens.sm && width < screens.md) {
				setScreenType('sm');
			}
			else if (width >= screens.md && width < screens.lg) {
				setScreenType('md');
			}
			else if (width >= screens.lg && width < screens.xl) {
				setScreenType('lg');
			}
			else if (width >= screens.xl && width < screens.xxl) {
				setScreenType('xl');
			}
			else {
				setScreenType('xxl');
			}
		}

		handleSetScreenSize();

		window.addEventListener('resize', () => {
            clearTimeout(timeout);
            timeout = setTimeout(handleSetScreenSize, delay);
            handleSetScreenSize();
        });
    }, []);

    return {
        screenWidth,
        screenType
    }
}

export default useScreen;
