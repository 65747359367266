import React, { useContext, useEffect, useState } from 'react';
import Context from '../context/Context';
import SearchResult from '../components/SearchResult';
import Search from '../components/Search';
import Pagination from '../components/Pagination';
import { handleEmptyStringCheck } from '../helpers/handleEmptyStringCheck';

const Results = () => {
    const { appName, setPageTitle, criteria, setCriteria, setCurrentCriteria, docsFound, docs } = useContext(Context);
    const [ indexGroup, setIndexGroup ] = useState([]);
    const [ currentIndexGroup, setCurrentIndexGroup ] = useState([]);
    const [ firstIndex, setFirstIndex ] = useState(0);
    const [ lastIndex, setLastIndex ] = useState(0);
    const [ currentIndex, setCurrentIndex ] = useState(0);
    const [ hasFilters, setHasFilters ] = useState(false);

    useEffect(() => {
        setPageTitle(`Search Results | ${appName}`);
    }, [appName]);

    // Recalc indices whenever criteria and/or docsFound state changes
    useEffect(() => {
        const arrayGroup = [...Array(Math.ceil(docsFound / criteria.rows)).keys()];

        setIndexGroup(arrayGroup);
        setCurrentIndex(0);
        setFirstIndex(arrayGroup[0]);
        setLastIndex(arrayGroup[arrayGroup.length - 1]);

        setHasFilters(criteria.filters.domain.length || criteria.filters.sites.length || (criteria.filters.dates.from.length && criteria.filters.dates.to.length) ? true : false);
    }, [criteria, docsFound]);

    // Recalc currentIndex
    useEffect(() => {
        setCurrentIndex(criteria.start / criteria.rows);
    }, [criteria, currentIndex]);

    // Recalc indexGroup
    // Render -5 and +5 from currentIndex
    useEffect(() => {
        if (currentIndex > 5) {
            setCurrentIndexGroup(indexGroup.slice(currentIndex - 5, currentIndex + 5));
        }
        else {
            setCurrentIndexGroup(indexGroup.slice(0, 10));
        }
    }, [indexGroup, currentIndex]);

    return (
        <>
            <h2>Search Results</h2>

            <Search setCurrentIndex={setCurrentIndex} />

            {criteria.q.replace(/\s/g, '').length > 0 && (
                <div className="md:flex md:justify-between md:items-center mb-8">
                    <div>
                        <p className="pr-4 text-sm md:text-base mb-1 md:mb-0">
                            <span><strong>{docsFound}</strong> search result{docsFound === 1 ? '' : 's'} for <span className="text-semibold text-amber font-semibold">"{criteria.q}"</span>. </span>
                            
                            <button className="text-blue-link hover:underline" onClick={() => {
                                const searchField = document.querySelector('#search');
                                searchField.focus();
                                
                                if (handleEmptyStringCheck(searchField.value)) {
                                    searchField.select();
                                }
                            }}>Search again?</button>
                        </p>
                        {hasFilters && (
                            <>
                                {/* criteria.filters.domain.length || criteria.filters.sites.length || criteria.filters.dates.length  */}
                                <span className="flex items-center text-[14px] mt-2 mb-2">
                                    <span>Filtering by:</span>
                                    {criteria.filters.domain.trim().length > 0 && (
                                        <span className="flex items-center ml-1 p-1 bg-gray-200 rounded">
                                            <span className="font-bold">Domain</span>
                                            <button className="ml-1" onClick={() => {
                                                setCurrentCriteria(prevState => ({
                                                    ...prevState,
                                                    filters: {
                                                        ...prevState.filters,
                                                        domain: ''
                                                    }
                                                }));

                                                setCriteria(prevState => ({
                                                    ...prevState,
                                                    filters: {
                                                        ...prevState.filters,
                                                        domain: ''
                                                    }
                                                }));
                                            }}>
                                                <span className="sr-only">Select to clear domain</span>
                                                <svg className="fill-gray-500 hover:fill-blue" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"/></svg>
                                            </button>
                                        </span>
                                    )}

                                    {criteria.filters.sites.trim().length > 0 && (
                                        <span className="flex items-center ml-1 p-1 bg-gray-200 rounded">
                                            <span className="font-bold">Sites</span>
                                            <button className="ml-1" onClick={() => {
                                                setCurrentCriteria(prevState => ({
                                                    ...prevState,
                                                    filters: {
                                                        ...prevState.filters,
                                                        sites: ''
                                                    }
                                                }));

                                                setCriteria(prevState => ({
                                                    ...prevState,
                                                    filters: {
                                                        ...prevState.filters,
                                                        sites: ''
                                                    }
                                                }));
                                            }}>
                                                <span className="sr-only">Select to clear sites</span>
                                                <svg className="fill-gray-500 hover:fill-blue" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"/></svg>
                                            </button>
                                        </span>
                                    )}

                                    {criteria.filters.dates.from.trim().length > 0 && criteria.filters.dates.to.trim().length > 0 && (
                                        <span className="flex items-center ml-1 p-1 bg-gray-200 rounded">
                                            <span className="font-bold">Date last modified</span>
                                            <button className="ml-1" onClick={() => {
                                                setCurrentCriteria(prevState => ({
                                                    ...prevState,
                                                    filters: {
                                                        ...prevState.filters,
                                                        dates: {
                                                            ...prevState.filters.dates,
                                                            from: '',
                                                            to: ''
                                                        }
                                                    }
                                                }));

                                                setCriteria(prevState => ({
                                                    ...prevState,
                                                    filters: {
                                                        ...prevState.filters,
                                                        dates: {
                                                            ...prevState.filters.dates,
                                                            from: '',
                                                            to: ''
                                                        }
                                                    }
                                                }));
                                            }}>
                                                <span className="sr-only">Select to clear dates</span>
                                                <svg className="fill-gray-500 hover:fill-blue" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"/></svg>
                                            </button>
                                        </span>
                                    )}

                                    <button className="text-blue-link hover:underline ml-1" onClick={() => {
                                        setCurrentCriteria(prevState => ({
                                            ...prevState,
                                            filters: {
                                                ...prevState.filters,
                                                domain: '',
                                                sites: '',
                                                dates: {
                                                    ...prevState.filters.dates,
                                                    from: '',
                                                    to: ''
                                                }
                                            }
                                        }));

                                        setCriteria(prevState => ({
                                            ...prevState,
                                            filters: {
                                                ...prevState.filters,
                                                domain: '',
                                                sites: '',
                                                dates: {
                                                    ...prevState.filters.dates,
                                                    from: '',
                                                    to: ''
                                                }
                                            }
                                        }));
                                    }}>
                                        <span className="sr-only">Select to </span>Clear All<span className="sr-only"> Filters</span>
                                    </button>
                                </span>

                                
                            </>
                        )}
                    </div>

                    {docsFound > criteria.rows && (
                        <Pagination classNames={`text-sm md:text-base no-flow`} indexGroup={indexGroup} currentIndexGroup={currentIndexGroup} firstIndex={firstIndex} lastIndex={lastIndex} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
                    )}
                </div>
            )}

            <ul className="flow-y">
                {docs?.map((doc, index) => {
                    return (
                        <li key={index} lang={doc.cdc_sys_lang_str && doc.cdc_sys_lang_str[0] !== 'en' ? doc.cdc_sys_lang_str[0] : ''}>
                            <SearchResult indexGroup={indexGroup} firstIndex={firstIndex} lastIndex={lastIndex} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} doc={doc} />
                        </li>
                    )
                })}
            </ul>

            {docsFound > criteria.rows && (
                <Pagination classNames={`text-sm md:text-base no-flow mt-10`} indexGroup={indexGroup} currentIndexGroup={currentIndexGroup} firstIndex={firstIndex} lastIndex={lastIndex} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
            )}
        </>
    )
}

export default Results;
