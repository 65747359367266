import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Context from './context/Context';
import Layout from './templates/Layout';
import Home from './pages/Home';
import Results from './pages/Results';
import Details from './pages/Details';
import FourZeroFour from './pages/404';
import useScreen from './hooks/useScreen';
import { handleInternalUrlCheck } from './helpers/handleInternalUrlCheck';
import { handleEmptyStringCheck } from './helpers/handleEmptyStringCheck';
import { handlePageviewBeacon } from './helpers/handlePageviewBeacon';
import './App.css';

const App = () => {
	const SOLR_API = window?.CDC_CONFIG?.solr;

	// Need to add the following later somehow
	// originalUrl: 'original_url:',
	// archiveUrl: 'archive_url:',
	// dateLastModified: 'date_last_modified:',
	const SOLR_CONFIGS = {
		fl: {
            title: 'title',
            dateArchived: 'date_archived',
            dateArchivedDisplay: 'date_archived_display',
            dateLastModified: 'date_last_modified',
            date_last_modified_display: 'date_last_modified_display',
            archiveUrl: 'archive_url',
            originalUrl: 'original_url',
            excerptTxt: 'excerpt_txt',
            cdcSysLangStr: 'cdc_sys_lang_str',
            cdcTopicStr: 'cdc_topic_str',
            cdcTopicSrch: 'cdc_topic_srch'
        },
		fq: {
			dateArchivedGmt: 'date_archived_gmt:[* TO NOW-15MINUTE]'
		}
	};

	// DEFAULT CRITERIA for the app to reset completely
	const defaultCriteria = {q: '', start: 0, rows: 10, filters: {domain: '', sites: '', dates: {from: '', to: ''}}};

	const [firstVisit, setFirstVisit] = useState(true);
    const [appName, setAppName] = useState('CDC Archive');
    const [pageTitle, setPageTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [criteria, setCriteria] = useState(defaultCriteria); // info used to formulate query param requests
    const [currentCriteria, setCurrentCriteria] = useState(defaultCriteria); // local and up-to-date copy of criteria
	// const [appData, setAppData] = useState(null);
	const [appSites, setAppSites] = useState(null);
    const [docsFound, setDocsFound] = useState(null);
    const [docs, setDocs] = useState(null);
    const [currentDoc, setCurrentDoc] = useState(null);
    const [isAtsdr, setIsAtsdr] = useState(false);
	
	const {screenType, screenWidth} = useScreen();

	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams({});

	const params = Object.fromEntries(new URLSearchParams(location.search));

	// On load we want to grab all "SITES" from archive JSON file
	useEffect(() => {
		const fetchSites = async () => {
			setIsLoading(true);

			try {
				const response = await fetch('./config/archive-sites.json');
				const json = await response.json();

				if (Object.keys(json).length) {
					const newSites = {}; // temp obj literal to push non-dupe sites into

					Object.keys(json).forEach((title) => {
						const urls = json[title];

						// Make sure we don't push duplicates into
						if (!newSites.hasOwnProperty(title)) {
							return newSites[title] = urls;
						}
					});

					Object.entries(newSites).sort();
					setAppSites(newSites);
				}
			}
			catch(err) {
				// error handle incase site retrieval fails
			}
			finally {
				setIsLoading(false);
			}
		}

		fetchSites();
	}, []);

	// Fire the pageview beacon anytime our pageTitle changes
	useEffect(() => {
		if (pageTitle) {
			document.title = pageTitle;
			
			return handlePageviewBeacon();
		}
	}, [pageTitle]);

	// Update canonical link tag: helps with Google indexing/crawling
	// Set ATSDR
	useEffect(() => {
		if (document.head.querySelector('link[rel="canonical"]')) document.head.querySelector('link[rel="canonical"]').href = !currentDoc ? window.location.href : `${window.location.origin}/#${location.pathname}?url=${currentDoc.original_url}`;

		if (location.pathname !== '/details' && appName !== 'CDC Archive') {
			setAppName('CDC Archive');
			setIsAtsdr(false);
		}

		// Set firstVisit state to false so we can start capturing metrics
		return () => setFirstVisit(false);
	}, [location.pathname, currentDoc]);

	// // Find any params and set our criterias based on that
	// // Will help with deep linking down the line
	// useEffect(() => {
	// 	// Set both our criterias so we can keep track of
	// 	if (params && Object.keys(params).length) {
	// 		setCriteria(prevState => ({
    //             ...prevState,
    //             q: params.q,
    //             start: params.start ? params.start : 0,
    //             rows: params.rows ? params.rows : 10
    //         }));

	// 		setCurrentCriteria(prevState => ({
    //             ...prevState,
    //             q: params.q,
    //             start: params.start ? params.start : 0,
    //             rows: params.rows ? params.rows : 10
    //         }));
	// 	}
	// }, []);

	useEffect(() => {
		const queryParam = searchParams.get('q');
		const startParam = searchParams.get('start');
		const rowsParam = searchParams.get('rows');
		const urlParam = searchParams.get('url');
		const archiveUrlParam = searchParams.get('archive_url');

		// Only initialize if we have a search query
		if (handleEmptyStringCheck(criteria.q)) {
			if (location.pathname === '/' || location.pathname === '/results') {
				// Check params for banned words
				if (!handleCheckParams(params)) {
					setCriteria((prevCriteria) => ({
						...prevCriteria,
						q: ''
					}));

					navigate(`/results?q=&start=${criteria.start}&rows=${criteria.rows}`);

					return;
				}

				handleDataFetch(criteria)
					.then((data) => {
						if (data.response.numFound === 1 || data.response.docs.length === 1) {
							const theDoc = data.response.docs[0];
							const isInternalDoc = handleInternalUrlCheck(theDoc);

							if (isInternalDoc) {
								setCurrentDoc(theDoc);
								navigate(`/details?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}&url=${theDoc.original_url}`);
							}
						}
						// For some reason, partial URL's more than 50 chars do not return any results
						// Here we .slice()' it down to 50 chars and re-query
						// We don't want to update the criteria.q state because this will cut off the query string, and looks ugly
						else if (data.response.numFound < 1 && criteria.q.length > 50) {
							let updatedCriteria = criteria;
							updatedCriteria.q = updatedCriteria.q.slice(-50, updatedCriteria.q.length);

							handleDataFetch(updatedCriteria)
								.then((data) => {
									// Go to /details page if only one result
									if (data.response.numFound === 1 || data.response.docs.length === 1) {
										const theDoc = data.response.docs[0];
										const isInternalDoc = handleInternalUrlCheck(theDoc);
			
										if (isInternalDoc) {
											setCurrentDoc(theDoc);
											navigate(`/details?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}&url=${theDoc.original_url}`);
										}
									}
									// Or go to /results page if multiple results
									else {
										navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}`);
									}
								});
						}
						else {
							navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}`);
						}
					});
			}
		}
		else if (location.pathname === '/results') {
			if (!handleCheckParams(params)) {
				setCriteria((prevCriteria) => ({
					...prevCriteria,
					q: ''
				}));

				navigate(`/results?q=&start=${criteria.start}&rows=${criteria.rows}`);

				return;
			}

			if (queryParam && handleEmptyStringCheck(queryParam)) {
				if (urlParam) {
					handleDataFetchByUrl(urlParam);
				}
				else {
					setCriteria((prevCriteria) => ({
						...prevCriteria,
						q: queryParam,
						start: startParam ? startParam : prevCriteria.start,
						rows: rowsParam ? rowsParam : prevCriteria.rows
					}));
				}
			}
		}
		else if (location.pathname === '/details') {
			if (!handleCheckParams(params)) {
				setCriteria((prevCriteria) => ({
					...prevCriteria,
					q: ''
				}));

				navigate(`/details?q=`);

				return;
			}

			// Check for both urlParam and archiveUrlParam
			if (urlParam && archiveUrlParam) {
				handleDataFetchByUrl(urlParam)
					.then((data) => {
						// Get the target document
						const targetDoc = data.response.docs.filter((doc) => doc.archive_url === archiveUrlParam)[0];

						setCurrentDoc(targetDoc);

						// If no target
						if (!targetDoc) {
							navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}`);
						}
						else {
							navigate(`/details?url=${targetDoc.original_url}&archive_url=${targetDoc.archive_url}`);
						}
					});
			}
			// Check if archiveUrlParam and no queryParam
			else if (archiveUrlParam && !urlParam) {
				handleDataFetchByArchiveUrl(archiveUrlParam)
					.then((data) => {
						// Check if we have any responses (documents)
						if (data.response.docs.length) {
							// Get and Set the target document
							const targetDoc = data.response.docs.filter((doc) => doc.archive_url === archiveUrlParam)[0];
							setCurrentDoc(targetDoc);
							navigate(`/details?archive_url=${archiveUrlParam}`);
						}
						else {
							navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}`);
						}
					});
			}
			// Check urlParam and no queryParam
			else if (urlParam && !queryParam) {
				handleDataFetchByUrl(urlParam)
					.then((data) => {
						if (data.response.numFound === 1 || data.response.docs.length === 1) {
							const theDoc = data.response.docs[0];
							const isInternalDoc = handleInternalUrlCheck(theDoc);

							if (isInternalDoc) {
								setCurrentDoc(theDoc);
								navigate(`/details?url=${theDoc.original_url}`);
							}
						}
						else {
							setCriteria((prevCriteria) => ({
								...prevCriteria,
								q: urlParam,
								start: startParam ? startParam : prevCriteria.start,
								rows: rowsParam ? rowsParam : prevCriteria.rows
							}));

							navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}&url=${urlParam}`);
						}
					});
			}
			// Check for both urlParam and queryParam
			else if (urlParam && queryParam) {
				setCriteria((prevCriteria) => ({
					...prevCriteria,
					q: queryParam,
					start: startParam ? startParam : prevCriteria.start,
					rows: rowsParam ? rowsParam : prevCriteria.rows
				}));

				handleDataFetchByQueryAndUrl(queryParam, urlParam)
					.then((data) => {
						const targetDocs = data.response.docs.filter((doc) => doc.original_url === urlParam);
						
						if (targetDocs.length === 1) {
							setCurrentDoc(targetDocs[0]);
						}
						else if (targetDocs.length > 1) {
							navigate(`/results?q=${queryParam}&start=${startParam}&rows=${rowsParam}&url=${urlParam}`);
						}
						else {
							// Nothing by that url
							// Redirecting to home page
							navigate(`/`);
						}
					})
			}
		}
	}, [criteria]);

	// DEFAULT data fetcher
	// Usually happens when user standard searches
	const handleDataFetch = async (c) => {
		setIsLoading(true);
		let json = {};

		try {
			let endpoint = await handleBuildEndpoint(c);
			let response = await fetch(endpoint);

			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			// setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	// Fetch data by ORGINAL URL (original_url)
	// Usually happens when user deep links with a original_url query param
	const handleDataFetchByUrl = async (url) => {
		setIsLoading(true);
		let json = {};

		try {
			const endpoint = await `${SOLR_API}?fl=title,date_archived,date_archived_display,original_url,archive_url,excerpt_txt,cdc_sys_lang_str,cdc_topic_str,cdc_topic_srch&fq=original_url:"${url}"`;
			const response = await fetch(endpoint);

			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			// setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	// Fetch data by ORGINAL URL (original_url) && QUERY
	// Usually happens when user deep links with both query (q) and url params
	const handleDataFetchByQueryAndUrl = async (q, url) => {
		setIsLoading(true);
		let json = {};

		try {
			const endpoint = await `${SOLR_API}?q=${q}&start=${criteria.start}&rows=${criteria.rows}&fl=title,date_archived,date_archived_display,original_url,archive_url,excerpt_txt,cdc_sys_lang_str,cdc_topic_str,cdc_topic_srch&fq=original_url:"${url}"`;
			const response = await fetch(endpoint);

			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			// setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	// Fetch data by ARCHIVE URL (archive_url)
	// Usually happens when user deep links with a archive_url query param
	const handleDataFetchByArchiveUrl = async (url) => {
		setIsLoading(true);
		let json = {};

		try {
			const endpoint = await `${SOLR_API}?fl=title,date_archived,date_archived_display,original_url,archive_url,excerpt_txt,cdc_sys_lang_str,cdc_topic_str,cdc_topic_srch&fq=archive_url:"${url}"`;
			const response = await fetch(endpoint);

			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			// setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	// Helper fn to build endpoint
	const handleBuildEndpoint = (outputCriteria) => {
		let url = `${SOLR_API}?q=${outputCriteria.q}&start=${outputCriteria.start}&rows=${outputCriteria.rows}`;

		// Loop through configs and apply static fields
		Object.keys(SOLR_CONFIGS).forEach((field) => {
            url += `&${field}=`;

			if (Object.keys(SOLR_CONFIGS[field]).length) {
				Object.keys(SOLR_CONFIGS[field]).forEach((item, index) => {
					url += `${SOLR_CONFIGS[field][item]}${index < Object.keys(SOLR_CONFIGS[field]).length - 1 ? ',' : ''}`
				});
			}
        });

		(outputCriteria.filters && Object.keys(outputCriteria.filters)).forEach((filter) => {
			const filterValue = outputCriteria.filters[filter];
			
			if (typeof filterValue === 'string') {
				if (filterValue && filterValue.trim().length > 0) {
					if (filter === 'domain') {
						url += `&fq=original_url:*${filterValue.replace('https://', '')}*`
					}
					else if (filter === 'sites') {
						let newSitesParams = '';

						appSites[filterValue].forEach((site, index) => {
							if (index === 0) {
								newSitesParams += `&fq=original_url:*${site.replace('https://', '')}*`
							} else {
								newSitesParams += ` AND original_url:*${site.replace('https://', '')}*`
							}
						});

						url += newSitesParams;
					}
				}
			}
			else if (typeof filterValue === 'object') {
				if (filter === 'dates') {
					const dateVals = Object.keys(filterValue).filter((item) => {
						if (filterValue[item].trim().length) {
							return filterValue[item]
						}
					});

					if (dateVals.indexOf('from') > -1 && dateVals.indexOf('to') > -1) {
						url += `&fq=date_last_modified:[`;

						dateVals.forEach((item) => {

							if (item === 'from') {
								url += `${filterValue[item]}T00:00:00Z TO `;
							}
							else if (item === 'to') {
								url += `${filterValue[item]}T24:00:00Z`;
							}

						});

						url += `]`;
					}
				}
			}
		})

		return url;
	};

	// Helper fn to reset criteria to default => sets the app to default
	const handleCriteriaReset = () => {
		setCriteria(defaultCriteria);
		setCurrentCriteria(defaultCriteria);
		// setAppData(null);
		setDocs(null);
		setDocsFound(null);
		setCurrentDoc(null);
	};

	// Helper fn to filter out illicit query params
	// Add more filtered words to 'FILTERED_WORDS' if needed
	const handleCheckParams = () => {
		const FILTERED_WORDS = 'telegram|instagram'.split('|');
		let pass = true;

		for (let param in params) {
			// @ symbol can be an email address or username
			if (params[param].match(/\@/)) {
				pass = false;
			}

			// url found in query param
			// if (params[param].match(/([\w-]+\.)+[\w-]{2,4}/)) {
			// 	pass = false;
			// }

			// banned words
			if (FILTERED_WORDS.includes(param.toLowerCase())) {
				pass = false;
			}

			if (FILTERED_WORDS.includes(params[param].toLowerCase())) {
				pass = false;
			}
		}

		return pass;
	};

	return (
		<Context.Provider value={{SOLR_API, SOLR_CONFIGS, firstVisit, appName, pageTitle, setPageTitle, setAppName, isLoading, setIsLoading, isError, errorMessage, criteria, setCriteria, currentCriteria, setCurrentCriteria, appSites, docsFound, setDocsFound, docs, setDocs, currentDoc, setCurrentDoc, isAtsdr, setIsAtsdr, screenType, screenWidth, handleCriteriaReset}}>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="results" element={<Results />} />
					<Route path="details" element={<Details />} />
					<Route path="*" element={<FourZeroFour />} />
				</Route>
			</Routes>
		</Context.Provider>
	);
}

export default App;
