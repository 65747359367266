import React from 'react';
import { useContext, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Context from '../context/Context';
import Button from '../objects/Button';
import { handleInteractionBeacon } from '../helpers/handleInteractionBeacon';

const Search = (props) => {
    const { label, setCurrentIndex } = props;
    const { appName, criteria, setCriteria, screenWidth } = useContext(Context);
    
    const [warning, setWarning] = useState(false);

    const searchForm = useRef(null);
    const searchField = useRef(null);

    const location = useLocation();

    const handleSearchCriteria = (event) => {
        event.preventDefault();
        
        const currentSearchText = searchField.current.value;

        if (currentSearchText && currentSearchText.replace(/\s/g, '').length) {
            handleInteractionBeacon( appName, 'o', 'form-submit' );

            setCriteria(prevCriteria => ({
                ...prevCriteria,
                q: currentSearchText,
                start: 0
            }));

            if (location.pathname === '/results') setCurrentIndex(0)
        }
        else {
            setWarning(true);
        }
    }

    return (
        <>
            {/* Only show on home page and on larger screens */}
            {location.pathname === '/' && screenWidth > 768 && (
                <p className="h3">Search the Archives</p>
            )}
        
            <form className={`${location.pathname === '/' && screenWidth > 768 ? 'md:bg-gray-200 md:p-4 flow-y' : ''}`} ref={searchForm} onSubmit={handleSearchCriteria}>
                <div>
                    <label className={`inline-block mb-2`} htmlFor="search">{label ? label : 'Search Text or URL'}</label>
                    <div className="relative">
                        <input id="search" className={`form-control w-full ${warning ? 'bg-pink-quaternary' : ''}`} name="search" type="text" defaultValue={criteria.q.length !== 0 ? criteria.q : ''} placeholder="Coronavirus" ref={searchField} onChange={(event) => {
                            // Also resets warning if users start typing
                            if (
                                warning &&
                                (
                                    event.target.value !== '' ||
                                    event.target.value.length > 0
                                )
                            ) {
                                setWarning(false);
                            }
                        }} />
                        <button className={`absolute top-[50%] translate-y-[-50%] right-2 w-[20px] h-[20px] ${location.pathname === '/' && screenWidth > 768 ? 'pointer-events-none' : ''}`} type="submit" tabIndex={location.pathname === '/' && screenWidth > 768 ? '-1' : '0'}>
                            <span className="sr-only">Select to search</span>
                            <svg className={`fill-gray-500 hover:fill-blue`} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><path d="M14.146 14.854l.708-.708-4.272-4.272C11.466 8.83 12 7.477 12 6c0-3.314-2.686-6-6-6-3.314 0-6 2.686-6 6 0 3.314 2.686 6 6 6 1.477 0 2.83-.534 3.874-1.418l4.272 4.272zM11 6c0 2.761-2.239 5-5 5-2.761 0-5-2.239-5-5 0-2.761 2.239-5 5-5 2.761 0 5 2.239 5 5z" /></svg>
                        </button>
                    </div>

                    {warning && (
                        <p className="text-pink text-sm mt-1">Search input cannot be empty.</p>
                    )}
                </div>

                {/* Only show on home page and on larger screens */}
                {location.pathname === '/' && screenWidth > 768 && (
                    <div className="flex items-center flow-x-sm">
                        <Button classNames="search-btn btn bg-blue hover:bg-blue-600 transition ease-[cubic-bezier(1, 0.5, 0, 0.74)] text-white" type="submit" onClick={() => handleInteractionBeacon( appName, 'o', 'form-search-click' )}><span className="sr-only">Select to </span>Search</Button>
                        <Button classNames="search-reset-btn btn disabled:bg-gray-400 bg-gray-500 hover:bg-gray-600 transition ease--cubic-bezier(1, 0.5, 0, 0.74) text-white" onClick={(event) => {
                            event.preventDefault();
                            searchField.current.value = '';
                            setWarning(false);
                            handleInteractionBeacon( appName, 'o', 'form-reset-click' );
                        }}><span className="sr-only">Select to </span>Reset</Button>
                    </div>
                )}
            </form>
        </>
    )
}

export default Search;
